@import url(https://fonts.bunny.net/css?family=roboto:100,300,400,500,700,900);

/* geomanist-book - latin */
@font-face {
    font-family: 'Geomanist';
    src: url('../fonts/geomanist-book-webfont.eot');
    src: url('../fonts/geomanist-book-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/geomanist-book-webfont.woff2') format('woff2'),
         url('../fonts/geomanist-book-webfont.woff') format('woff'),
         url('../fonts/geomanist-book-webfont.ttf') format('truetype'),
         url('../fonts/geomanist-book-webfont.svg#geomanistregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-900 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/roboto-v30-latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v30-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v30-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v30-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v30-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v30-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
  }